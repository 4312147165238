import React, { useState, useEffect } from "react";
import DepositNotice from "../../notice/mobile/DepositNotice";
import { Link, useNavigate } from "react-router-dom";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";

function MDeposit() {
  const [activeItem, setActiveItem] = useState(null); // Selected payment method
  const [activeAmount, setActiveAmount] = useState(""); // Selected deposit amount
  const [accountNumber, setAccountNumber] = useState(""); // Sender account number
  const [transactionId, setTransactionId] = useState(""); // TRX ID
  const [items, setItems] = useState([]); // Payment methods
  const [promotions, setPromotions] = useState([]); // Promotions data
  const [selectedPromotion, setSelectedPromotion] = useState(null); // Selected promotion
  const [isLoading, setIsLoading] = useState(true); // Loading state for API
  const [paymentMethod, setPaymentMethod] = useState(null); // Selected payment method
  const [paymentSystem, setPaymentSystem] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false); // Submit button loading state
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Modal message
  const token = localStorage.getItem("token");
  const amounts = ["2000", "3000", "5000", "10000", "15000", "20000", "25000", "1000", "500", "100"]; // Predefined amounts

  const navigate = useNavigate(); // Initialize navigate

  // Fetch payment methods from API
  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/payment-methods`)
      .then((response) => response.json())
      .then((data) => setItems(data))
      .catch((error) => console.error("Error fetching payment methods:", error))
      .finally(() => setIsLoading(false));
  }, []);

  // Fetch promotions from API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/promotion`)
      .then((response) => response.json())
      .then((data) => {
        const activePromotions = data.filter((promo) => promo.status === "active");
        setPromotions(activePromotions);
        if (activePromotions.length > 0) {
          setSelectedPromotion(activePromotions[0]);
        }
      })
      .catch((error) => console.error("Error fetching promotions:", error));
  }, []);

  useEffect(() => {
    if (paymentMethod?.id) {
      const fetchPaymentMethodDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/showpayment-menthod?id=${paymentMethod.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          if (data && data.success) {
            setPaymentSystem(data.data);
            console.log("Fetched Payment System:", data.data);
          }
        } catch (error) {
          console.error("Error fetching payment method details:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchPaymentMethodDetails();
    }
  }, [paymentMethod, token]);

  const handleItemClick = (id) => {
    const selectedMethod = items.find((item) => item.id === id);
    setPaymentMethod(selectedMethod); 
    setActiveItem(id); 
  };

  const handleAmountClick = (amount) => {
    setActiveAmount(amount); 
  };

  const handlePromotionChange = (event) => {
    const selectedId = event.target.value;
    const selectedPromo = promotions.find((promo) => promo.id === parseInt(selectedId));
    setSelectedPromotion(selectedPromo || null); 
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const remoteId = localStorage.getItem("remote_id");

    const requestBody = {
      remote_id: remoteId,
      account_number: accountNumber,
      transaction_id: transactionId,
      amount: activeAmount,
      payment_id: activeItem,
      promotion_id: selectedPromotion?.id || null,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-deposit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setModalMessage("Submission failed: " + errorData.message);
        setShowModal(true); 
        return;
      }

      const data = await response.json();

      if (data) {
        setModalMessage("Submission successful!");
       
        navigate("/payment-request", {
          state: {
            amount: activeAmount,
            payment_id: activeItem,
          },
        });
      } else {
        setModalMessage(data.message);
        setShowModal(true); // Show modal with the error message
      }
    } catch (error) {
      setModalMessage("An error occurred. Please try again.");
      setShowModal(true); // Show modal on error
    } finally {
      setSubmitLoading(false);
    }
  };
  if(isLoading) {
    return(
        <div className="three-dots-loading">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
}

  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title">Funds</div>
        <div
          className="logo"
          tabIndex={0}
          style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }}
        />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{ width: "50%" }} />
              <div className="btn active">
                <Link className="text-white" to="/deposit">
                  <div className="text">Deposit</div>
                </Link>
              </div>
              <div className="btn">
                <Link className="text-white" to="/withdrawal">
                  <div className="text">Withdrawal</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                    {/* Promotion Selector */}
                    <div className="option-group select-bar">
                      <label>
                        <span
                          className="item-icon"
                          style={{
                            backgroundImage:
                              'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-selectpromotion.svg")',
                          }}
                        />
                        <div>Select Promotion</div>
                      </label>
                      <div className="option-wrap">
                        <select
                          onChange={handlePromotionChange}
                          defaultValue={selectedPromotion?.id || ""}
                        >
                          {promotions.map((promotion) => (
                            <option key={promotion.id} value={promotion.id}>
                              {promotion.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <DepositNotice />

                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                          {items.map(
                            (item) =>
                              item.status === "active" && (
                                <li
                                  key={item.id}
                                  onClick={() => handleItemClick(item.id)}
                                  className="ng-star-inserted"
                                >
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    id={`paymentMethod_${item.id}`}
                                  />
                                  <label htmlFor={`paymentMethod_${item.id}`}>
                                    <div className="bank ng-star-inserted">
                                      <img
                                        alt={item.name}
                                        src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                                        loading="lazy"
                                      />
                                    </div>
                                    <span>{item.name}</span>
                                    <div className="tag-rebate-money"><p ><span>+</span>{selectedPromotion?.deposit_bouns ? Math.round(selectedPromotion.deposit_bouns) : 0}
                                    <span>%</span></p></div>
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>
                          Amount <i>৳ 500.00 - ৳ 25,000.00</i>
                        </h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                          {amounts.map((amount) => (
                            <li
                              key={amount}
                              onClick={() => handleAmountClick(amount)}
                            >
                              <input
                                type="radio"
                                name="depositAmount"
                                id={`depositAmount_${amount}`}
                              />
                              <label htmlFor={`depositAmount_${amount}`}>
                                <span>{amount}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            name="amount"
                            value={activeAmount}
                            placeholder="0.00"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="accountNumber">Account</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            style={{ paddingRight: "60px" }}
                            value={paymentSystem?.account_number || ""}
                            readOnly
                          />
                          <button
                            onClick={() => {
                              if (paymentSystem?.account_number) {
                                navigator.clipboard.writeText(
                                  paymentSystem.account_number
                                );
                                alert(`Account number copied: ${paymentSystem.account_number}`);
                              } else {
                                alert("No account number to copy!");
                              }
                            }}
                            style={{
                              position: "absolute",
                              right: "0",
                              top: "0",
                              bottom: "0",
                              background: "transparent",
                              padding: "5px 10px",
                              cursor: "pointer",
                            }}
                          >
                            <img src="./icons/copy.svg" alt="Copy" />
                          </button>
                        </div>
                      </div>

                      <div className="input-group money">
                        <label htmlFor="accountNumber">Sender Account</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            name="accountNumber"
                            value={accountNumber}
                            onChange={(e) =>
                              setAccountNumber(e.target.value)
                            }
                            placeholder="Account Here"
                          />
                        </div>
                      </div>

                      <div className="input-group money">
                        <label htmlFor="transactionId">TRX ID</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            name="transactionId"
                            value={transactionId}
                            onChange={(e) =>
                              setTransactionId(e.target.value)
                            }
                            placeholder="TRX ID Here"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div
                      style={{ textAlign: "center" }}
                      className="member-content "
                    >
                      <button
                        className="button"
                        onClick={handleSubmit}
                        disabled={submitLoading}
                      >
                        {submitLoading ? "Submitting..." : "Submit"}
                      </button>
                    </div>

                    {/* Modal for Messages */}
                    {showModal && (
                      <div className="modal">
                        <div className="modal-content">
                          <p>{modalMessage}</p>
                          <button onClick={() => setShowModal(false)}>
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar />
    </div>
  );
}

export default MDeposit;
