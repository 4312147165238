import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Toolbar from '../../navigation/mobile/Toolbar';
import UniversalBack from '../../common/mobile/UniversalBack';

const VIPConvert = () => {
  const [vpValue, setVpValue] = useState('');
  const [showError, setShowError] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

  const handleVpChange = (e) => {
    const value = e.target.value;
    setVpValue(value);
    setShowError(value && value < 10);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');

      if (token && remoteId) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUserData(data);
          } else {
            console.error('Failed to fetch user data:', response.status);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    if (isLoggedIn) {
      fetchUserData();
      const interval = setInterval(fetchUserData, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  const handleConvert = async () => {
    const remoteId = localStorage.getItem('remote_id');
    const token = localStorage.getItem('token');

    if (vpValue < 10) {
      setModalMessage('Minimum VP Required: 10');
      setShowModal(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/convert-vipcoin?remote_id=${remoteId}&vip_coin=${vpValue}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setModalMessage(data.success);
      } else {
        setModalMessage(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error converting VIP coins:', error);
      setModalMessage('An error occurred while converting coins');
    } finally {
      setShowModal(true);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="main-router-wrapper ng-trigger ng-trigger-routeFullPageAni">
      <header id="header" className="player vip scroll-detecting ng-star-inserted">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title ng-star-inserted">My VIP</div>
      </header>

      <div className="wrap">
        <div className="content player-content vip-content">
          <div className="ng-trigger ng-trigger-routeLayoutPageAni">
            <div className="ng-trigger ng-trigger-staggerFadeAnimation ng-star-inserted">
              <div className="item-ani player-vip-box deco vip-card lv12">
                <div className="card-top ng-star-inserted">
                  <div className="vip-lv-area">
                    <div className="lv-totem">
                      <span className="item-icon ng-star-inserted" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/vip/bdt/expertII.png?v=1730201806250")' }} />
                    </div>
                    <div className="lv-text">
                      <div className="text">VIP LEVEL</div>
                      <h2>Expert III</h2>
                    </div>
                    <div className="lv-history">
                      <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/player/vip/icon-history.svg?v=1730201806250")' }} />
                      <a href="/bd/en/member/vip-history" />
                    </div>
                  </div>
                </div>
                <div className="card-bottom ng-star-inserted">
                  <a href="/bd/en/vip-detail">
                    View VIP Details <span className="item-icon" style={{ maskImage: 'url("/assets/images/icon-set/player/vip/icon-arrow.svg")' }} />
                  </a>
                </div>
              </div>

              <div className="item-ani player-vip-box total">
                <div className="title">Total VP</div>
                <div className="status-box">
                  <div className="status">
                    <div className="number">{userData ? userData.vip_coin : '0'}</div>
                    <div className="text">VP</div>
                  </div>
                  <div className="cleader ng-star-inserted">
                    <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/player/vip/icon-points.svg")' }} />
                    <a className="vip-cleader" href="/bd/en/member/vip-points-records" />
                  </div>
                </div>
              </div>

              <div className="item-ani player-vip-box cash-card">
                <div className="title">
                  <h2><span> Convert VP </span></h2>
                </div>
                <div className="cash-points ng-trigger ng-trigger-fadeInOut ng-star-inserted">
                  <div className="coin">
                    <div className="movie-box">
                      <video width="100%" height="100%" autoPlay playsInline muted loop poster="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver.png">
                        <source src="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver-alpha.mov" type="video/quicktime" />
                        <source src="https://img.m167cw.com/mcw/h5/assets/images/vip/coin-rotate-silver-alpha.webm" type="video/webm" />
                      </video>
                    </div>
                    <div className="convert-icon">
                      <div className="chevron" />
                      <div className="chevron" />
                    </div>
                    <div className="movie-box">
                      <video width="100%" height="100%" autoPlay playsInline muted loop poster="https://img.b112j.com/bj/h5/assets/images/vip/bdt/coin-rotate-gold.png">
                        <source src="https://img.j189eb.com/jb/h5/assets/images/vip/bdt/coin-rotate-gold-alpha.mov" type="video/quicktime" />
                        <source src="https://img.j189eb.com/jb/h5/assets/images/vip/bdt/coin-rotate-gold-alpha.webm" type="video/webm" />
                      </video>
                    </div>
                  </div>
                  <div className="cash-detail">
                    <div className={`cash-input ${showError ? 'error' : ''}`}>
                      <div className="detail-title">
                        <span>VP</span>
                        <p className="text">Minimum VP Required: <span>10</span></p>
                      </div>
                      <input
                        type="number"
                        inputMode="numeric"
                        placeholder="0"
                        className="ng-untouched ng-pristine ng-valid"
                        value={vpValue}
                        onChange={handleVpChange}
                      />
                    </div>
                    {showError && (
                      <div className="conversion">
                        <div className="error-tip ng-star-inserted">
                          <div className="text">Not enough points. Points must follow minimum VP required to convert.</div>
                        </div>
                      </div>
                    )}
                    <div className="cash-input">
                      <div className="detail-title"><span>Real Money</span></div>
                      <input
                        type="number"
                        inputMode="numeric"
                        placeholder="0"
                        value={vpValue}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="item-ani button-ani button default" onClick={handleConvert}>
                Convert to Real Money
                <img alt="convert-button-dfbg" src="https://img.j189eb.com/jb/h5/assets/images/player/vip/convert-button-dfbg.png" loading="lazy" />
              </div>

              <div id="convert-content" className="convert-content ng-star-inserted">
                <div className="convert-ani ng-trigger ng-trigger-fadeInOut ng-star-inserted">
                  <div className="coin-block">
                    <video width="100%" height="100%" playsInline muted loop poster="https://img.j189eb.com/jb/h5/assets/images/vip/bdt/coin-change.png">
                      <source src="https://img.j189eb.com/jb/h5/assets/images/vip/bdt/coin-change.mov" type="video/quicktime" />
                      <source src="https://img.j189eb.com/jb/h5/assets/images/vip/bdt/coin-change.webm" type="video/webm" />
                    </video>
                    <div className="convert-success text">Success !</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className='msg' show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                
            </Modal>

      <Toolbar />
    </div>
  );
};

export default VIPConvert;
