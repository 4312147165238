import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

function ChatTongole() {
  const chatRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 70,
    y: window.innerHeight - 150,
  });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [dragDistance, setDragDistance] = useState(0);

  const handleStart = (e) => {
    setDragging(true);
    setDragDistance(0); // Reset drag distance at the start

    const event = e.type === "touchstart" ? e.touches[0] : e;
    const rect = chatRef.current.getBoundingClientRect();

    setOffset({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });

    // Add global event listeners
    if (e.type === "mousedown") {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleEnd);
    } else if (e.type === "touchstart") {
      document.addEventListener("touchmove", handleMove, { passive: false });
      document.addEventListener("touchend", handleEnd);
    }
  };

  const handleMove = (e) => {
    if (!dragging) return;

    const event = e.type === "touchmove" ? e.touches[0] : e;
    e.preventDefault(); // Prevent scrolling on touch devices

    const newX = event.clientX - offset.x;
    const newY = event.clientY - offset.y;

    // Update the drag distance
    const distance = Math.sqrt(
      Math.pow(newX - position.x, 2) + Math.pow(newY - position.y, 2)
    );
    setDragDistance(distance);

    setPosition({ x: newX, y: newY });
  };

  const handleEnd = (e) => {
    // Prevent link click if dragging occurred
    if (dragDistance > 10) {
      e.preventDefault();
    }

    setDragging(true);

    // Remove global event listeners
    document.removeEventListener("mousemove", handleMove);
    document.removeEventListener("mouseup", handleEnd);
    document.removeEventListener("touchmove", handleMove);
    document.removeEventListener("touchend", handleEnd);
  };

  return (
    <Link to="/chat">
      <div
        ref={chatRef}
        className="chat-tongole"
        style={{
          position: "fixed",
          left: `${position.x}px`,
          top: `${position.y}px`,
          cursor: dragging ? "grabbing" : "grab",
          zIndex: 1000,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "#333",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        <img
          src="./icons/wbj-white.png"
          alt="Chat Icon"
          style={{ width: "26px" }}
        />
      </div>
    </Link>
  );
}

export default ChatTongole;
