import React, { useState, useEffect } from "react";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import NoData from "../../common/mobile/NoData";

function BettingRecord() {
    const [activeTab, setActiveTab] = useState("settle");
    const [providers, setProviders] = useState([]);
    const [gameTypes, setGameTypes] = useState([]);
    const [bettingRecords, setBettingRecords] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState("All");
    const [selectedType, setSelectedType] = useState("All");
    const [selectedDate, setSelectedDate] = useState("Last 7 days");

    const filterOpen = () => {
        document.querySelector('.searchpage').classList.add('active');
        document.querySelector('.searchpage-bar').classList.add('active');
    };

    const filterClose = () => {
        document.querySelector('.searchpage').classList.remove('active');
        document.querySelector('.searchpage-bar').classList.remove('active');
    };

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            const headers = { Authorization: `Bearer ${token}` };
            try {
                const [providersResponse, typesResponse, recordsResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_API_URL}/api/get-provider`, { headers }),
                    fetch(`${process.env.REACT_APP_API_URL}/api/get-type`, { headers }),
                    fetch(`${process.env.REACT_APP_API_URL}/api/betting-record`, { headers })
                ]);

                if (providersResponse.ok && typesResponse.ok && recordsResponse.ok) {
                    const providersData = await providersResponse.json();
                    const typesData = await typesResponse.json();
                    const recordsData = await recordsResponse.json();
                    setProviders(providersData.data);
                    setGameTypes(typesData.types);
                    setBettingRecords(recordsData);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const isDateInRange = (date, range) => {
        const today = new Date();
        const txnDate = new Date(date);
        const lastWeek = new Date(today);
        lastWeek.setDate(lastWeek.getDate() - 7);

        return range === "Today" ? txnDate.toDateString() === today.toDateString() :
               range === "Yesterday" ? txnDate.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString() :
               range === "Last 7 days" ? txnDate >= lastWeek && txnDate <= today : true;
    };

    const filteredRecords = bettingRecords.filter(record => {
        const matchesPlatform = selectedPlatform === "All" || record.platform === selectedPlatform;
        const matchesType = selectedType === "All" || record.game_type === selectedType;
        const matchesDate = isDateInRange(record.created_at, selectedDate);
        return matchesPlatform && matchesType && matchesDate;
    });

    const groupedRecords = filteredRecords.reduce((acc, record) => {
        const date = new Date(record.created_at).toDateString();
        if (!acc[date]) acc[date] = [];
        acc[date].push(record);
        return acc;
    }, {});

    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                    <UniversalBack />
                </div>
                <div className="header-title">Betting Records</div>
                
                <div className="header-right-btn-group">
                    <a name="helpCenter" className="service-btn">
                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-service-icon.svg?v=1729676340443")' }} />
                    </a>
                </div>
            </header>

            <div className="wrap">
                <div className="content fixed-tab player-content">
                    <div className="tab-btn-section">
                        <div className="tab-btn tab-btn-page">
                            <div className="line" style={{ width: 'calc(50%)', transform: activeTab === "unsettle" ? 'translate(100%, 0px)' : 'translate(0%, 0px)' }} />
                            <div className={`btn ${activeTab === "settle" ? "active" : ""}`} onClick={() => setActiveTab("settle")}>
                                <div className="text">Settled</div>
                            </div>
                            <div className={`btn ${activeTab === "unsettle" ? "active" : ""}`} onClick={() => setActiveTab("unsettle")}>
                                <div className="text">Unsettled</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content tab-content-page">
                        <div className="ng-trigger ng-trigger-tabPageTriggerAni">
                            <div className="ng-trigger ng-trigger-staggerFadeAnimation tab filter-tab">
                                <ul className="item-ani">
                                    <li className="active">{selectedDate}</li>
                                </ul>
                                <div onClick={filterOpen} className="btn search-btn">
                                <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/games-filter-icon.svg?v=1729676340443")' }} />
                                </div>
                            </div>
                            <div className="searchpage">
    <div onClick={filterClose} className="search-top-info">
        <div className="back">
        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")' }} />
            Back
        </div>
        <input type="text" className="ng-untouched ng-pristine" placeholder="Betting Record Filter" disabled style={{ backgroundImage: 'url("")' }} />
    </div>
    <div className="searchpage-main">
        <div className="search-checkbox-group check-group">
            <h2>Platform</h2>
            <ul>
                {providers.map(provider => (
                    <li key={provider.platform}>
                        <input type="checkbox" id={`checkbox-${provider.platform}`} name={provider.platform} 
                            onChange={() => setSelectedPlatform(provider.platform === selectedPlatform ? "All" : provider.platform)} 
                            checked={selectedPlatform === provider.platform} />
                        <label htmlFor={`checkbox-${provider.platform}`}>{provider.platform}</label>
                    </li>
                ))}
            </ul>
        </div>
        <div className="search-checkbox-group check-group">
            <h2>Game Type</h2>
            <ul>
                {gameTypes.map(gameType => (
                    <li key={gameType}>
                        <input type="checkbox" id={`checkbox-${gameType}`} name={gameType} 
                            onChange={() => setSelectedType(gameType === selectedType ? "All" : gameType)} 
                            checked={selectedType === gameType} />
                        <label htmlFor={`checkbox-${gameType}`}>{gameType}</label>
                    </li>
                ))}
            </ul>
        </div>
        <div className="search-checkbox-group check-group">
            <h2>Date</h2>
            <ul>
                <li>
                    <input type="radio" id="date-today" name="date-filter" 
                        onChange={() => setSelectedDate("Today")} 
                        checked={selectedDate === "Today"} />
                    <label htmlFor="date-today">Today</label>
                </li>
                <li>
                    <input type="radio" id="date-yesterday" name="date-filter" 
                        onChange={() => setSelectedDate("Yesterday")} 
                        checked={selectedDate === "Yesterday"} />
                    <label htmlFor="date-yesterday">Yesterday</label>
                </li>
                <li>
                    <input type="radio" id="date-last7days" name="date-filter" 
                        onChange={() => setSelectedDate("Last 7 days")} 
                        checked={selectedDate === "Last 7 days"} />
                    <label htmlFor="date-last7days">Last 7 days</label>
                </li>
            </ul>
        </div>
    </div>
    <div className="searchpage-bar">
        <button className="button" onClick={filterClose}>Confirm</button>
    </div>
</div>

                            <div className={`${activeTab === "settle" ? 'd-block' : 'd-none'}`}>
                                <div className="inner-wrap">
                                    <div className="inner-box">
                                    <div className="record-item item-title transaction-record-list">
                                    <div className="item type">Platform</div>
                                    <div className="item amount">Game Type</div>
                                    <div className="item status">Turnover</div>
                                    <div className="item time">Profit/Loss</div>
                                </div>
                                        {Object.keys(groupedRecords).length > 0 ? (
                                            Object.keys(groupedRecords).map(date => (
                                                <div key={date} className="list list-betting-record">
                                                    <div className="date-title">
                                                        <div className="date">
                                                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-calendar-type02.svg?v=1730201806250")' }} />
                                                            {date}
                                                        </div>
                                                        <div className="time-zone">GMT+6</div>
                                                    </div>
                                                    <div className="list-content">
                                                        {groupedRecords[date].map((record, index) => (
                                                            <div key={index} className="record-item transaction-record-list no-detail-info">
                                                                <div className="item platform">{record.platform}</div>
                                                                <div className="item type">{record.game_type}</div>
                                                                <div className="item bet">{record.bet_amount}</div>
                                                                <div className="item profit">{record.winAmount === 0 ? record.bet_amount : record.winAmount}</div>
                                                                <div className="list-arrow" 
                                                                style={{display: 'block', 
                                                                maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1730201806250")'}} />
                                                                
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <NoData />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toolbar />
        </div>
    );
}

export default BettingRecord;
