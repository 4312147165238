import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import WithdrawNotice from "../../notice/mobile/WithdrawNotice";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import { Modal } from "react-bootstrap"; 

function MWithdrawal() {
  const [activeItem, setActiveItem] = useState(null); // Selected payment method
  const [activeAmount, setActiveAmount] = useState(""); // Selected withdrawal amount
  const [items, setItems] = useState([]); // Payment methods
  const [accountNumber, setAccountNumber] = useState(""); // Account number
  const [submitLoading, setSubmitLoading] = useState(false); // Submit button loading state
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Modal message
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const remoteId = localStorage.getItem('remote_id');

  const amounts = ["2000", "3000", "5000", "10000", "15000", "20000", "25000", "1000", "500", "100"]; // Predefined amounts

  // Fetch payment methods from API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/payment-methods`)
      .then((response) => response.json())
      .then((data) => setItems(data))
      .catch((error) => console.error("Error fetching payment methods:", error));
  }, []);

  const handleItemClick = (id) => {
    setActiveItem(id); // Update selected payment method
  };

  const handleAmountClick = (amount) => {
    setActiveAmount(amount); // Update selected withdrawal amount
  };
  const checkUserEligibility = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/userwithdrawcheck?remote_id=${remoteId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();
      
      if (!response.ok) {
        setModalMessage(data.error || 'An error occurred.');
        setShowModal(true); // Show modal if there's an error
        return false;
      }

      if (data.success) {
        return true;
      } else {
        setModalMessage(data.error);
        setShowModal(true); // Show modal if there's an error
        return false;
      }
    } catch (error) {
      setModalMessage('An error occurred while checking withdrawal eligibility.');
      setShowModal(true); // Show modal if there's an error
      return false;
    }
  };


  const handleSubmit = async () => {
    const eligible = await checkUserEligibility();
    setSubmitLoading(true);
    const remoteId = localStorage.getItem("remote_id");

    const requestBody = {
      remote_id: remoteId,
      account_number: accountNumber,
      amount: activeAmount,
      payment_id: activeItem,
    };
    console.log(requestBody);

   if(eligible) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-withdraw`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setModalMessage(errorData.error);
        setShowModal(true); 
        return;
      }

      const data = await response.json();

      if (data.success) {
        setModalMessage("Withdrawal successful!");
        setShowModal(true);
        navigate("/payment-request", {
          state: { amount: activeAmount, payment_id: activeItem },
        });
      } else {
        setModalMessage(data.message);
        setShowModal(true); // Show modal with the error message
      }
    } catch (error) {
      setModalMessage("An error occurred. Please try again.");
      setShowModal(true); // Show modal on error
    } finally {
      setSubmitLoading(false);
    }
   }
  };
  

  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title">Funds</div>
        <div
          className="logo"
          tabIndex={0}
          style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }}
        />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{ width: "calc(50%)", transform: "translate(100%, 0px)" }} />
              <div className="btn">
                <Link className="text-white" to="/deposit">
                  <div className="text">Deposit</div>
                </Link>
              </div>
              <div className="btn active">
                <Link className="text-white" to="/withdrawal">
                  <div className="text">Withdrawal</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                    <WithdrawNotice />

                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                          {items.map(
                            (item) =>
                              item.status === "active" && (
                                <li
                                  key={item.id}
                                  onClick={() => handleItemClick(item.id)}
                                  className="ng-star-inserted"
                                >
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    id={`paymentMethod_${item.id}`}
                                  />
                                  <label htmlFor={`paymentMethod_${item.id}`}>
                                    <div className="bank ng-star-inserted">
                                      <img
                                        alt={item.name}
                                        src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                                        loading="lazy"
                                      />
                                    </div>
                                    <span>{item.name}</span>
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>
                          Amount <i>৳ 500.00 - ৳ 25,000.00</i>
                        </h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                          {amounts.map((amount) => (
                            <li
                              key={amount}
                              onClick={() => handleAmountClick(amount)}
                            >
                              <input
                                type="radio"
                                name="withdrawAmount"
                                id={`withdrawAmount_${amount}`}
                              />
                              <label htmlFor={`withdrawAmount_${amount}`}>
                                <span>{amount}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            name="amount"
                            value={activeAmount}
                            placeholder="0.00"
                            readOnly
                          />
                        </div>
                      </div>

                      {/* Account Number */}
                      <div className="input-group money">
                        <label htmlFor="accountNumber">Account Number</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            name="accountNumber"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            placeholder="Enter your account number"
                          />
                        </div>
                      </div>

                      <div
                        className="tips-info note ng-star-inserted"
                        style={{}}
                      >
                        <h5 className="">
                          <i
                            className="tips-icon"
                            style={{
                              maskImage:
                                'url("/assets/images/icon-set/icon-tips-type02.svg")',
                            }}
                          />
                          <span className="">
                            Reminder : <br/> 1. Please double check the recipient's
                            account details before proceeding. <br/> 2. DO NOT share
                            your account with anyone to avoid losing funds or
                            money. <br/> 3. Please make sure your bank account holder
                            name and our registered name match to prevent
                            withdrawal rejection.
                          </span>
                        </h5>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div style={{textAlign: 'center'}} className="member-content">
                      
                        <button className="button" onClick={handleSubmit} disabled={submitLoading}>
                          {submitLoading ? "Submitting..." : "Submit"}
                        </button>
                      
                    </div>

                    {/* Modal for Messages */}
                    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{modalMessage}</p>
                            </Modal.Body>
                        </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar />
    </div>
  );
}

export default MWithdrawal;
