import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEnvelope, faEnvelopeOpen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import NoData from "../common/mobile/NoData";

function ChatHistory() {
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({
        id: '',
        
    });
    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        if (token && remoteId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const formattedDate = data.created_at ? new Date(data.created_at).toLocaleDateString('en-GB') : 'N/A';
                    
                    setUserData({
                        id: data.id || 'Unknown User',
                        
                    });
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const startChat = async () => {
        const token = localStorage.getItem("token");
        const remoteId = localStorage.getItem("remote_id");

        if (token && remoteId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        user_one_id: userData.id,
                        user_two_id: "7", 
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    window.location.href = `/chat-box/${data.chat.id}`;
                } else {
                    console.error("Failed to start a new chat.");
                }
            } catch (error) {
                console.error("Error starting chat:", error);
            }
        }
    };

    useEffect(() => {
        const fetchChatHistory = async () => {
            const token = localStorage.getItem("token");
            const remoteId = localStorage.getItem("remote_id");

            if (token && remoteId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-chats`, {
                        method: "GET",
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setChats(data.chats);
                    } else {
                        console.error("Failed to fetch chat history.");
                    }
                } catch (error) {
                    console.error("Error fetching chat history:", error);
                }
            }
            setLoading(false);
        };

        fetchChatHistory();
    }, []);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="chat-historys">
            <div className="chat-historys-header">
                <div></div>
                <h3 className="text-white">Messages</h3>
                <Link to={"/"}>
                    <FontAwesomeIcon className="text-white icon" icon={faXmark} />
                </Link>
            </div>

            <div className="chat-his-body">
                {chats.length > 0 ? (
                    chats.map((chat) => (
                        <div className="chat-list" key={chat.id}>
                            <div className="chat-list-body">
                                <div className="d-flex chat-list-msg">
                                    <img src="./avatars/blank.png" alt="icon-sponsor" />
                                    <div className="chat-list-content">
                                        <span>{chat.other_user.username}</span>
                                        
                                        <p>
                                            {chat.last_message} 
                                        </p>
                                    </div>
                                </div>
                                <Link to={`/chat-box/${chat.chat_id}`}>
                                    <FontAwesomeIcon className="text-dark icon" icon={faChevronRight} />
                                </Link>
                            </div>
                        </div>
                    ))
                ) : (
                    <NoData />
                )}
            </div>

            <div className="send-us">
                <button onClick={startChat} className="btn btn-primary">
                    Send us a Message <FontAwesomeIcon icon={faEnvelopeOpen} />
                </button>
            </div>

            <div className="chat-footer">
                <ul className="chat-nav">
                    <li>
                        <Link to={"/chat"}>
                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                            <p>Home</p>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/chat-list"}>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <p>Message</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ChatHistory;
