import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const sections = [
    { title: "Sports", icon: "./icons/icon-sport.png", type: "SPORTS" },
    { title: "Casino", icon: "./icons/icon-casino.png", type: "LIVE" },
    { title: "Slot", icon: "./icons/icon-slot.png", type: "SLOT" },
    { title: "Table", icon: "./icons/icon-table.png", type: "TABLE" },
    { title: "Poker", icon: "./icons/icon-arcade.png", type: "BINGO" },
    { title: "Crash", icon: "./icons/icon-crash.png", type: "EGAME" },
    { title: "Fishing", icon: "./icons/icon-fish.png", type: "FH" },
    { title: "Lottery", icon: "./icons/icon-lottery.png", type: "LOTTO" },
    
];

const sportsOptions = [
    { name: "Exchange", icon: "icon-exchange.svg", link: "/exchange" },
    { name: "I-Sports", icon: "icon-sportbook.svg" },
    { name: "E1Sports", icon: "icon-awcme1sport.svg" },
    { name: "Horsebook", icon: "icon-horsebook.svg" },
    { name: "Virtual", icon: "icon-virtual.svg" },
    { name: "Kabaddi", icon: "icon-kabaddi.svg" },
    { name: "Fantasy", icon: "icon-powerelevenv2.svg" },
    { name: "CMD Sports", icon: "icon-cmd.svg" },
];

const GameNavIcon = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [systems, setSystems] = useState([]);
    const [providers, setProviders] = useState([]);
    const [error, setError] = useState(null);
    const [contentPosition, setContentPosition] = useState("0px");
    const navigate = useNavigate();

    useEffect(() => {
        const selectedSection = sections[activeIndex];
        if (selectedSection.type && selectedSection.type !== "SPORTS") {
            fetchSystems(selectedSection.type);
        } else {
            setSystems([]);
            setProviders([]);
        }
    }, [activeIndex]);
    const handleCasinoClick = (system, type, title) => {
        navigate('/games', { state: { system, type, title } });
    };

    const handleSectionClick = (index) => {
        setActiveIndex(index);
        setContentPosition("-100%");
        setTimeout(() => setContentPosition("0px"), 500);
    };

    const fetchSystems = async (type) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-system?type=${type}`);
            const data = await response.json();
            if (response.ok) {
                setSystems(data.systems || []);
                fetchProviders(data.systems);
            } else {
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            console.error('Error fetching systems:', error);
            setError('An unexpected error occurred while fetching systems');
        }
    };

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            if (response.ok) {
                setProviders(data.data || []);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };

    return (
        <div className="mcd-game-nav-icon-inner">
            <div id="nav" className="nav nav-category nav-auto">
                {sections.map((section, index) => (
                    <div
                        key={index}
                        className={`btn ${activeIndex === index ? 'selected' : ''}`}
                        onClick={() => handleSectionClick(index)}
                    >
                        <div className="icon">
                            <span
                                className="item-icon"
                                style={{
                                    backgroundImage: `url(${section.icon})`
                                }}
                            ></span>
                        </div>
                        <p>{section.title}</p>
                    </div>
                ))}
            </div>
            <div className="nav-wrap">
                <div id="nav-title" className="content-title">
                    <h2>
                        <span>{sections[activeIndex].title}</span>
                    </h2>
                </div>
                <div className="nav-content-wrap">
                    <div
                        className="nav-content-inner"
                        style={{
                            transform: `translateX(${contentPosition})`,
                            transition: "transform 0.5s ease"
                        }}
                    >
                        <div className="content-box">
                            <div className="layout-brand">
                                <div className="card1">
                                    {sections[activeIndex].type === "SPORTS" ? (
                                        <ul>
                                            {sportsOptions.map((sport, index) => (
                                                <li key={index}>
                                                    <Link to={sport.link}>
                                                        <div
                                                            className="icon-sport"
                                                            style={{
                                                                maskImage: `url(https://img.j189eb.com/jb/h5/assets/images/icon-set/sports-icon/${sport.icon}?v=1729676340443)`
                                                            }}
                                                        ></div>
                                                        <p>{sport.name}</p>
                                                   
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <ul>
                                            {providers.length > 0 ? (
                                                providers.map((provider, index) => (
                                                    <li key={index}>
                                                        <a onClick={() => handleCasinoClick(provider.platform, sections[activeIndex].type, sections[activeIndex].title )} >
                                                            <img
                                                                src={`./icons/platform/${provider.platform}.png`}
                                                                alt={provider.platform}
                                                                className="ng-star-inserted"
                                                            />
                                                            <p>{provider.platform}</p>
                                                        </a>
                                                    </li>
                                                ))
                                            ) : (
                                                <p>No providers available</p>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameNavIcon;
