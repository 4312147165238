import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import Footer from "../../layout/mobile/Footer";
import Toolbar from "../../navigation/mobile/Toolbar";
import { Link } from "react-router-dom";
import UniversalBack from "../../common/mobile/UniversalBack";

function RefrrelProgram() {
    const [activeTab, setActiveTab] = useState("info");
    const referralCode = "pRQ3QN";
    const referralLink = `https://worldbaji.live/referral/${referralCode}`;

    const copyReferralLink = async () => {
        try {
            await navigator.clipboard.writeText(referralLink);
            alert("Referral link copied to clipboard!");
        } catch (error) {
            prompt("Copy this link:", referralLink);
        }
    };

    const shareReferralLink = () => {
        if (navigator.share) {
            navigator.share({
                title: "Join me on this platform!",
                text: `Use my referral code to sign up: ${referralCode}`,
                url: referralLink,
            }).catch(console.error);
        } else {
            alert("Sharing is not supported on this device.");
        }
    };

    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                   <UniversalBack/>
                </div>
                <div className="header-title">Referral Program</div>
                <div className="logo" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }} />
            </header>

            <div className="wrap">
                <div className="content player-content full-content">
                    <div className="tab-btn-section">
                        <div className="tab-btn tab-btn-page">
                            <div className="line" style={{ width: 'calc(50%)', transform: activeTab === "info" ? 'translate(0, 0)' : 'translate(100%, 0)' }} />
                            <div className={`btn ${activeTab === "info" ? "active" : ""}`} onClick={() => setActiveTab("info")}>
                                <div className="text">Referral Info</div>
                            </div>
                            <div className={`btn ${activeTab === "referral" ? "active" : ""}`} onClick={() => setActiveTab("referral")}>
                                <div className="text">My Referral</div>
                            </div>
                        </div>
                    </div>

                    {activeTab === "info" ? (
                       <div className="main-router-wrapper">
                       <div className="wrap">
                           <div className="content player-content full-content">
                               <div className="referral-program-promotion">
                                   {/* Background Image */}
                                   <div className="referral-program-promotion__bg">
                                       <img
                                           src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-bg.png"
                                           alt="background-deco"
                                           loading="lazy"
                                       />
                                   </div>
           
                                   {/* Banner Section */}
                                   <div className="referral-program-promotion__head">
                                       <div className="referral-program-promotion__banner referral-program-promotion-banner">
                                           <div className="referral-program-promotion-banner__img">
                                               <img
                                                   src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-banner.png"
                                                   alt="referral-program-banner"
                                                   loading="eager"
                                               />
                                           </div>
                                       </div>
                                   </div>
           
                                   {/* How to Earn More Rewards Guide */}
                                   <div className="referral-program-promotion__body">
                                       <div className="referral-program-promotion__panel referral-program-promotion-panel referral-program-promotion__guide referral-program-promotion-guide">
                                           <div className="referral-program-promotion-guide__title referral-program-promotion-panel__title">
                                               How to earn more rewards
                                           </div>
                                           <div className="referral-program-promotion-guide__flowch referral-program-promotion-flowch">
                                               {/* Step 1 */}
                                               <div className="referral-program-promotion-flowch__step referral-program-promotion-step-block referral-program-promotion-step-block--left">
                                                   <div className="referral-program-promotion-step-block__img referral-program-promotion-step-block__img--a">
                                                       <img
                                                           src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-flowch-1.png"
                                                           alt="flowch-step-icon"
                                                           loading="lazy"
                                                       />
                                                   </div>
                                                   <div className="referral-program-promotion-step-block__num">1</div>
                                                   <div className="referral-program-promotion-step-block__body">
                                                       <div className="referral-program-promotion-step-block__arrow referral-program-promotion-step-block__arrow--a1">
                                                           <img
                                                               src="https://img.j189eb.com/jb/h5/assets/images/referral-program/arrow-deco.png"
                                                               alt="arrow-deco"
                                                               loading="lazy"
                                                           />
                                                       </div>
                                                       <div className="referral-program-promotion-step-block__title">Send an invitation</div>
                                                       <div className="referral-program-promotion-step-block__descrp">To start your referral journey</div>
                                                   </div>
                                               </div>
           
                                               {/* Step 2 */}
                                               <div className="referral-program-promotion-flowch__step referral-program-promotion-step-block referral-program-promotion-step-block--right">
                                                   <div className="referral-program-promotion-step-block__img referral-program-promotion-step-block__img--b">
                                                       <img
                                                           src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-flowch-2.png"
                                                           alt="flowch-step-icon"
                                                           loading="lazy"
                                                       />
                                                   </div>
                                                   <div className="referral-program-promotion-step-block__num">2</div>
                                                   <div className="referral-program-promotion-step-block__body">
                                                       <div className="referral-program-promotion-step-block__arrow referral-program-promotion-step-block__arrow--b1">
                                                           <img
                                                               src="https://img.j189eb.com/jb/h5/assets/images/referral-program/arrow-deco.png"
                                                               alt="arrow-deco"
                                                               loading="lazy"
                                                           />
                                                       </div>
                                                       <div className="referral-program-promotion-step-block__title">Friend registration</div>
                                                       <div className="referral-program-promotion-step-block__descrp">with bets placed</div>
                                                   </div>
                                               </div>
           
                                               {/* Step 3 */}
                                               <div className="referral-program-promotion-flowch__step referral-program-promotion-step-block referral-program-promotion-step-block--left">
                                                   <div className="referral-program-promotion-step-block__img referral-program-promotion-step-block__img--c">
                                                       <img
                                                           src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-flowch-3.png"
                                                           alt="flowch-step-icon"
                                                           loading="lazy"
                                                       />
                                                   </div>
                                                   <div className="referral-program-promotion-step-block__num">3</div>
                                                   <div className="referral-program-promotion-step-block__body">
                                                       <div className="referral-program-promotion-step-block__arrow referral-program-promotion-step-block__arrow--c1">
                                                           <img
                                                               src="https://img.j189eb.com/jb/h5/assets/images/referral-program/arrow-deco.png"
                                                               alt="arrow-deco"
                                                               loading="lazy"
                                                           />
                                                       </div>
                                                       <div className="referral-program-promotion-step-block__title">
                                                           Get rewarded daily, weekly, monthly
                                                       </div>
                                                       <div className="referral-program-promotion-step-block__descrp">and enjoy with your friends</div>
                                                   </div>
                                               </div>
                                           </div>
                                           <button className="referral-program-promotion-guide__btn referral-program-promotion-panel__btn">
                                               Refer a friend now
                                           </button>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                      
                    ) : (
                        <div className="referral-program">
                            <div className="referral-program__bg">
                                <img
                                    alt="background"
                                    src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-bg.png"
                                    loading="lazy"
                                />
                            </div>
                            <div className="referral-program__head">
                                <div className="referral-program-promotion__banner referral-program-promotion-banner">
                                    <div className="referral-program-promotion-banner__img">
                                        <img
                                            alt="referral-program-banner"
                                            src="https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-program-banner.png"
                                            loading="eager"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="referral-program__body">
                                <div
                                    className="referral-program__panel referral-program-panel referral-code-panel"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-panel-bg-1.png")' }}
                                >
                                    <div className="referral-code-panel__title">Your Referral Code</div>
                                    <div className="referral-code-panel__code code-panel-area">
                                        <span className="code-panel-area__code">{referralCode}</span>
                                        <span
                                            className="code-panel-area__icon"
                                            onClick={copyReferralLink}
                                            style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-copy.svg")' }}
                                        />
                                    </div>
                                    <div className="referral-code-panel__qrcode referral-code-panel-qrcode">
                                        <QRCodeCanvas value={referralLink} size={128} />
                                    </div>
                                    <div className="referral-code-panel__btn-wrap">
                                        <button className="referral-code-panel__btn" onClick={copyReferralLink}>
                                            Copy Link
                                        </button>
                                        <button className="referral-code-panel__btn" onClick={shareReferralLink}>
                                            Share
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="referral-program__panel referral-program-downline-board"
                                    style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/referral-program/referral-panel-bg-2.png")' }}
                                >
                                    <div className="referral-program-downline-board__tag">
                                        Reward details
                                    </div>
                                    <div className="referral-program-downline-board__head">
                                        <div className="referral-program-downline-board__downline">0</div>
                                        <div className="referral-program-downline-board__guide">
                                            Active downlines
                                            <img
                                                alt="question"
                                                src="https://img.j189eb.com/jb/h5/assets/images/referral-program/question.svg"
                                                loading="lazy"
                                            />
                                        </div>
                                    </div>
                                    <div className="referral-program-downline-board__ul">
                                        <div className="referral-program-downline-board__li">
                                            <div className="referral-program-downline-board__status" data-title="Lifetime Cash Rewards">0</div>
                                        </div>
                                        <div className="referral-program-downline-board__li">
                                            <div className="referral-program-downline-board__status" data-title="Referral Turnover">0</div>
                                        </div>
                                        <div className="referral-program-downline-board__li">
                                            <div className="referral-program-downline-board__status referral-program-downline-board__status--left" data-title="Cash Rewards">0</div>
                                            <button className="referral-program-downline-board__btn">Claim All</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
            <Toolbar />
        </div>
    );
}

export default RefrrelProgram;
