import React, { useState, useEffect } from "react";
import GameViewList from "../../games/GameViewList";

const FilterComponent = () => {
    const [systems, setSystems] = useState([]);
    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]); // Store selected providers
    const [error, setError] = useState(null);

    const fetchSystems = async (type = "LIVE") => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-system?type=${type}`);
            const data = await response.json();
            console.log("Fetched Systems:", data);
            if (response.ok) {
                setSystems(data.systems || []);
                fetchProviders(data.systems); // Fetch providers based on fetched systems
            } else {
                console.error('Failed to fetch systems:', data.message);
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            setError('An unexpected error occurred while fetching systems');
        }
    };

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            console.log("Fetched Providers:", data);
            if (response.ok) {
                setProviders(data.data || []);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };

    useEffect(() => {
        fetchSystems(); // Fetch systems on mount
        return () => {
            setSystems([]);
            setProviders([]);
        };
    }, []);

    const handleProviderSelection = (platform) => {
        setSelectedProviders(prev =>
            prev.includes(platform)
                ? prev.filter(item => item !== platform) // Remove if already selected
                : [...prev, platform] // Add to selected
        );
    };

    return (
        <>
        <div className="top-subnav-wrap">
            <div className="top-subnav-inner">
                <div id="category_filter_collapse_container" className="select-wrap-collapse">
                    <ul className="select-platform-wrap">
                        {providers.length > 0 ? (
                            providers.map((provider) => (
                                <li key={provider.platform}>
                                    <input 
                                        type="checkbox" 
                                        id={`chk-${provider.platform}`} 
                                        checked={selectedProviders.includes(provider.platform)} // Set checkbox state
                                        onChange={() => handleProviderSelection(provider.platform)} 
                                    />
                                    <label htmlFor={`chk-${provider.platform}`}>
                                        <span className="check-cube"></span>
                                        <p className="name">{provider.platform}</p>
                                        <span 
                                            className="game-logo data-image" 
                                            style={{ backgroundImage: `url('./icons/platform/${provider.platform}.png')` }}
                                        ></span>
                                    </label>
                                </li>
                            ))
                        ) : (
                            <li>No providers available</li>
                        )}
                    </ul>
                    {error && <p className="error">{error}</p>}
                </div>
            </div>
            
        </div>
        <GameViewList currentProviders={selectedProviders} /> 
        </>
    );
};

export default FilterComponent;
