import React, { useState, useEffect } from "react";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import NoData from "../../common/mobile/NoData";

function TransactionRecord() {
    const [transactionData, setTransactionData] = useState([]);
    const [statusFilter, setStatusFilter] = useState("All");
    const [paymentTypeFilter, setPaymentTypeFilter] = useState("All");
    const [dateFilter, setDateFilter] = useState("Today");
    const [isCurrentTab, setIsCurrentTab] = useState(true);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    // Open/close filter modal
    const openFilter = () => setIsFilterOpen(true);
    const closeFilter = () => setIsFilterOpen(false);

    // Open/close transaction details popup
    const openPopup = (transaction) => setSelectedTransaction(transaction);
    const closePopup = () => setSelectedTransaction(null);

    // Toggle between tabs in transaction details
    const toggleTab = () => setIsCurrentTab(!isCurrentTab);
    console.log(statusFilter, paymentTypeFilter, dateFilter);

    const handleStatusFilterChange = (status) => {
        setStatusFilter(status);
      };
    
      const handleActionFilterChange = (action) => {
        setPaymentTypeFilter(action);
      };
    
      const handleDateFilterChange = (dateRange) => {
        setDateFilter(dateRange);
      };

    

    // Fetch transaction data on component mount
    useEffect(() => {
        const fetchTransData = async () => {
            const token = localStorage.getItem("token");
            const remoteId = localStorage.getItem("remote_id");
            if (token && remoteId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transaction/show?remote_id=${remoteId}`, {
                        method: "GET",
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setTransactionData(data);
                    } else {
                        console.error("Failed to fetch data:", response.status);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchTransData();
    }, []);

    // Helper function to check if a date is in a specific range
    const isDateInRange = (date, range) => {
        const today = new Date();
        const txnDate = new Date(date);
    
        if (range === "Today") {
            return txnDate.toDateString() === today.toDateString();
        } else if (range === "Yesterday") {
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            return txnDate.toDateString() === yesterday.toDateString();
        } else if (range === "Last 7 days") {
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7);
            return txnDate >= lastWeek && txnDate <= today;
        }
        return true;
    };

    // Apply selected filters to transactions
    const filteredTransactions = transactionData.filter((transaction) => {
        const isStatusMatch =
            statusFilter === "All" ||
            (statusFilter === "Success" && transaction.key === "1") ||
            (statusFilter === "Pending" && transaction.key === "0") ||
            (statusFilter === "Fail" && transaction.key === "2");
    
        const isPaymentTypeMatch =
            paymentTypeFilter === "All" ||
            (paymentTypeFilter === "Deposit" && transaction.action === "deposit") ||
            (paymentTypeFilter === "Withdrawal" && transaction.action === "withdraw");
    
        const isDateMatch = isDateInRange(transaction.created_at, dateFilter);
    
        return isStatusMatch && isPaymentTypeMatch && isDateMatch;

        
    });

    // Group transactions by date for display
    const groupedTransactions = filteredTransactions.reduce((grouped, transaction) => {
        const date = new Date(transaction.created_at).toDateString();
        if (!grouped[date]) grouped[date] = [];
        grouped[date].push(transaction);
        return grouped;
    }, {});

    

    return (
        <div className="main-router-wrapper">
            <header id="header" className="login player">
                <div className="header-left-btn-group">
                    <UniversalBack />
                </div>
                <div className="header-title">Transaction Records</div>
                
                <div className="header-right-btn-group">
                    <a name="helpCenter" className="service-btn">
                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-service-icon.svg?v=1729676340443")' }} />
                    </a>
                </div>
            </header>

            <div className="wrap">
                <div className="content player-content">
                    {/* Filter Section */}
                    <div className="tab filter-tab">
                        <ul className="item-ani">
                            <li className="active" onClick={() => setDateFilter("Today")}>{dateFilter}</li>
                        </ul>
                        <div onClick={openFilter} className="btn search-btn">
                            <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/games-filter-icon.svg?v=1729676340443")' }} />
                        </div>
                    </div>

                    {/* Filter Modal */}
                    {isFilterOpen && (
                        <div className="searchpage active">
                            <div className="search-top-info">
                                <div onClick={closeFilter} className="back">
                                    <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")' }} />
                                    Back
                                </div>
                                <input type="text" placeholder="Transaction Record Filter" disabled />
                            </div>
                            <div className="searchpage-main">
                                <div className="search-checkbox-group check-group">
                                    <h2>Status</h2>
                                    <ul>
                                        {["All", "Pending", "Fail", "Success"].map((status) => (
                                            <li key={status}
                                            onClick={() => handleStatusFilterChange(status)}
                                            >
                                                <input
                                                    type="radio"
                                                    name="statusFilter"
                                                    checked={statusFilter === status}
                                                    onChange={() => setStatusFilter(status)}
                                                />
                                                <label>{status}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="search-checkbox-group check-group">
                                    <h2>Payment Type</h2>
                                    <ul>
                                        {["All", "Deposit", "Withdrawal"].map((type) => (
                                            <li key={type}
                                            onClick={() => handleActionFilterChange(type)}
                                            >
                                                <input
                                                    type="radio"
                                                    name="paymentTypeFilter"
                                                    checked={paymentTypeFilter === type}
                                                    onChange={() => setPaymentTypeFilter(type)}
                                                />
                                                <label>{type}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="search-checkbox-group check-group">
                                    <h2>Date</h2>
                                    <ul>
                                        {["Today", "Yesterday", "Last 7 days", "All"].map((date) => (
                                            <li key={date}
                                            onClick={() => handleDateFilterChange(date)}
                                            >
                                                <input
                                                    type="radio"
                                                    name="dateFilter"
                                                    checked={dateFilter === date}
                                                    onChange={() => setDateFilter(date)}
                                                />
                                                <label>{date}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="searchpage-bar active">
                                <button className="button" onClick={closeFilter}>Confirm</button>
                            </div>
                        </div>
                    )}

                    {/* Transaction Records */}
                    <div className="record-item item-title transaction-record-list">
                        <div className="item type">Type</div>
                        <div className="item amount">Amount</div>
                        <div className="item status">Status</div>
                        <div className="item time">Txn Date</div>
                    </div>
                    {Object.keys(groupedTransactions).length > 0 ? (
                        Object.keys(groupedTransactions).map((date, idx) => (
                            <div key={idx} className="list list-betting-record">
                                <div className="date-title">
                                    <div className="date">
                                        <span className="item-icon" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-calendar-type02.svg?v=1730201806250")' }} />
                                        {date}
                                    </div>
                                    <div className="time-zone">GMT+6</div>
                                </div>
                                <div className="list-content">
                                    {groupedTransactions[date].map((transaction, index) => (
                                        <div
                                            key={index}
                                            className="record-item transaction-record-list no-detail-info"
                                            onClick={() => openPopup(transaction)}
                                        >
                                            <div className="item type">{transaction.action}</div>
                                            <div className="item amount">৳{transaction.amount}</div>
                                            <div className={`item status ${transaction.key === "1" ? "positive" : transaction.key === "2" ? "negative" : "pending"}`}>
                                                <div className="tags">{transaction.key === "1" ? "Success" : transaction.key === "2" ? "Fail" : "Processing"}</div>
                                            </div>
                                            <div className="item time">{new Date(transaction.created_at).toLocaleTimeString()}</div>
                                            <div className="list-arrow" 
                            style={{display: 'block', 
                            maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1730201806250")'}} />
                                            
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    ) : (
                       <NoData/>
                    )}
                </div>
            </div>

            {/* Transaction Popup */}
            {selectedTransaction && (
               <>
                <div className="pop-transaction-records-details active">
                    <div className="bank-name">
                        {selectedTransaction.payment_method?.icon && (
                            <img alt="Payment Method" src={`${process.env.REACT_APP_API_URL}/storage/${selectedTransaction.payment_method.icon}`} loading="lazy" />
                        )}
                        <span>{selectedTransaction.payment_method?.name || "Agent"}</span>
                    </div>
                    <a onClick={closePopup} className="btn-closed" style={{ maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-cross-type01.svg?v=1730201806250")' }} />
                    <div className="header">Transaction Record Details</div>
                    <div className={`content pop-content ${selectedTransaction.key === "1" ? "success" : selectedTransaction.key === "2" ? "fail" : "processing"}`}>
                        <div className="tab-btn-section tab-btn-wrap" onClick={toggleTab}>
                            <div className="tab-btn tab-btn-bar">
                                <div className="line" style={{ width: 'calc(50%)', transform: isCurrentTab ? "translate(0%, 0px)" : "translate(100%, 0px)" }} />
                                <div className="btn" data-tab-current="current">
                                    <img className="icon" alt="icon-timeline" src="https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-timeline.svg?v=1730201806250" loading="lazy" />
                                </div>
                                <div className="btn">
                                    <img className="icon" alt="icon-table" src="https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-table.svg?v=1730201806250" loading="lazy" />
                                </div>
                            </div>
                        </div>
                        <div className="tab-content tab-content-page">
                            <div className="inner-wrap">
                                {/* Progress Tab */}
                                <div className="inner-box" style={{ display: isCurrentTab ? "block" : "none" }}>
                                    <div className="transaction-details-wrap">
                                        <div className="title">
                                            <h3>Transaction Progress</h3>
                                            <div className="tags">{selectedTransaction.key === "1" ? "Success" : selectedTransaction.key === "2" ? "Fail" : "Processing"}</div>
                                        </div>
                                        <div className="timeline-box">
                                        <div className="date">{new Date(selectedTransaction.updated_at).toLocaleTimeString()}</div>
                                            <div className="timeline-block current">
                                                <div className="point bounce">
                                                {selectedTransaction.key === "1" ?  <span className="item-icon" style={{ webkitMaskImage: 'url("./icons/icon-check-type06.svg")' }} />
                                                 : selectedTransaction.key === "2" ?<span className="item-icon" style={{ webkitMaskImage: 'url("./icons/icon-cross-type07.svg")' }} /> : "Processing"}
                                                   
                                                </div>
                                                <div className="content">
                                                    <div className="text">Your {selectedTransaction.action} Is Successful.</div>
                                                    <div className="time">{new Date(selectedTransaction.updated_at).toLocaleTimeString()}</div>
                                                </div>
                                            </div>
                                            <div className="date">{new Date(selectedTransaction.created_at).toLocaleTimeString()}</div>
                                            <div className="timeline-block">
                                                <div className="point">
                                                    
                                                </div>
                                                <div className="content">
                                                    <div className="text">{selectedTransaction.action} Information Received.</div>
                                                    <div className="time">{new Date(selectedTransaction.created_at).toLocaleTimeString()}</div>
                                                </div>
                                            </div>
                                            <div className="date">{new Date(selectedTransaction.created_at).toLocaleTimeString()}</div>
                                        <div className="timeline-block">
                                            <div className="point">
                                                
                                            </div>
                                            <div className="content" style={{animation: '1s ease 0.4s 1 normal none running slide'}}>
                                                <div className="text">{selectedTransaction.action} Initiated.</div>
                                                <div className="time">{new Date(selectedTransaction.created_at).toLocaleTimeString()}</div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Details Tab */}
                                <div className="inner-box" style={{ display: !isCurrentTab ? "block" : "none" }}>
                                    <div className="transaction-details-wrap">
                                        <div className="title">
                                            <h3>Transaction Record Details</h3>
                                            <div className="tags">Success</div>
                                        </div>
                                        <div className="details-box">
                                            <div className="info">
                                                <div className="name">No.</div>
                                                <div className="value">{selectedTransaction.transaction_id}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Type</div>
                                                <div className="value">{selectedTransaction.payment_method?.type || "Agent"}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Payment Method</div>
                                                <div className="value">{selectedTransaction.payment_method?.name || "Agent"}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Bank Name</div>
                                                <div className="value">{selectedTransaction.payment_method?.name || "Agent"}</div>
                                            </div>
                                            <div className="info">
                                                <div className="name">Amount</div>
                                                <div className="value">৳{selectedTransaction.amount}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="pop-bg d-block" onClick={closePopup} />
               </>
            )}
            <Toolbar />
        </div>
    );
}

export default TransactionRecord;
