import React, { useEffect, useState } from "react";
import MainNav from "../navigation/desktop/MianNav";
import { Link } from "react-router-dom";


const Header = () => {
  const [time, setTime] = useState("");

  useEffect(() => {
    // Update time to GMT+6
    const updateTime = () => {
      const options = { timeZone: "Asia/Dhaka", hour12: false };
      const currentTime = new Date().toLocaleTimeString("en-GB", options);
      setTime(currentTime);
    };
    updateTime();
    const timeInterval = setInterval(updateTime, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  const [logo, setLogo] = useState([]);
  useEffect(() => {
      const fetchPromotions = async () => {
          try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
              if (!response.ok) {
                  throw new Error("Failed to fetch promotions");
              }
              const data = await response.json();
              setLogo(data[0].logo);
          } catch (error) {
              console.error("Error fetching promotions:", error);
          } 
      };
  
      fetchPromotions();
  }, []);

  return (
    <header className="mini-header">
      <div className="status-wrap">
        <div className="header-inner">
          <span id="header_inner_time" className="time" title="2024/10/26">
            (GMT+6) {time}
          </span>
          <div id="language_wrap" className="language-wrap language-wrap-pop">
            <div id="lang_select" className="language">
              <img src="https://www.jeetbuzz.com/images/web/flag/BD.png" alt="en" />
              <p>
                <span>৳&nbsp;BDT</span>&nbsp;English
              </p>
            </div>
          </div>
          <div className="header-online-sevice">
            <ul id="header_cs_container" className="online-sevice-inner">
              <li id="cs-header-149">
                <a className="icon-whatsapp">WhatsApp</a>
              </li>
              <li id="cs-header-151">
                <a className="icon-fb">Facebook</a>
              </li>
              <li id="cs-header-150">
                <a href="mailto:support.bd@jeetbuzz.com" target="_blank" rel="noopener noreferrer" className="icon-mail">
                  Email
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="logo-wrap">
        <div className="header-inner">
          <div
            className="logo data-image active"
            style={{ backgroundImage: `url('https://six6.site/storage/${logo}')` }}
          ></div>
          <div className="hd-spon">
            <ul className="hd-spon__icon">
              <li>
                <img className="spon-icon" src="https://www.jeetbuzz.com/images/web/sponsor/delhi-bulls.png" alt="icon-sponsor" />
              </li>
            </ul>
          </div>
          <dl className="header-login">
            <dt>
              <span>
                <a className="balance-main">Main Wallet</a>
              </span>
              <span className="amount totalBalanceWallet">
                <span className="balance" style={{ paddingRight: "7px" }}>৳</span>
                <p className="balance">0</p>
              </span>
              <span className="amount">
                <a className="reload" data-reloadbalance="true"></a>
              </span>
            </dt>
            <dd>
              <Link className="btn-default" to={'/account'}>Deposit</Link>
              <Link className="btn-2nd" to={'/account'}>Member Center</Link>
              
              
              <a className="btn-3rd" onClick={() => (window.location = "/player/logout")}>
                Log out
              </a>
            </dd>
          </dl>
        </div>
      </div>
      <MainNav/>
    </header>
  );
};

export default Header;
