import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function PaymentTimer() {
    const [arrivalTime, setArrivalTime] = useState(45); // Time in minutes for countdown
    const [animationActive, setAnimationActive] = useState(true); // Toggle for animations
    const location = useLocation(); // Get the location object
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const [paymentSystem, setPaymentSystem] = useState('');

    // Extract amount and payment_id from location state
    const { amount, payment_id } = location.state || {}; // Default to an empty object if state is undefined

    useEffect(() => {
        // Countdown for arrival time in minutes
        const countdown = setInterval(() => {
            setArrivalTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 60000); // Decrement every minute (60000ms = 1 minute)

        return () => clearInterval(countdown);
    }, []);

    useEffect(() => {
        if (payment_id) {
            const fetchPaymentMethodDetails = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/showpayment-menthod?id=${payment_id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    const data = await response.json();

                    if (data && data.success) {
                        setPaymentSystem(data.data);
                    }
                } catch (error) {
                    console.error('Error fetching payment method details:', error);
                }
            };

            fetchPaymentMethodDetails();
        }
    }, [payment_id, token]);

    useEffect(() => {
        // Initiating animations when the component is mounted
        if (animationActive) {
            const aniBox = document.querySelector('.ani-box');
            const beforeAmount = document.querySelector('.before-amount');
            const afterAmount = document.querySelector('.after-amount');
            const greenCircle = document.querySelector('.green-circle');
            const payment = document.querySelector('.payment');
            const buttonBox = document.querySelector('.button-box');

            // Animation for before amount
            beforeAmount.style.opacity = 1;
            beforeAmount.style.transform = 'translateY(0)';

            // Animation for after amount
            setTimeout(() => {
                afterAmount.style.opacity = 1;
                afterAmount.style.transform = 'translateY(0)';
            }, 1000);

            // Animation for green circle
            setTimeout(() => {
                greenCircle.style.opacity = 1;
                greenCircle.style.transform = 'scale(1)';
            }, 2000);

            // Animation for payment
            setTimeout(() => {
                payment.style.opacity = 1;
                payment.style.transform = 'translateY(0)';
            }, 3000);

            // Animation for button box
            setTimeout(() => {
                buttonBox.style.opacity = 1;
                buttonBox.style.transform = 'translateY(0)';
            }, 4000);

            // Animation for ani box
            setTimeout(() => {
                aniBox.style.opacity = 1;
                aniBox.style.transform = 'translateY(0)';
            }, 5000);
        }
    }, [animationActive]);

    return (
        <div className="main-router-wrapper">
            <div className="bg-wrap">
                <div className="bg-inner">
                    <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
                        <defs>
                            <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
                                <stop offset="0%" style={{ stopColor: 'rgba(235, 145, 19)' }} />
                                <stop offset="100%" style={{ stopColor: 'rgba(235, 19, 109)' }} />
                            </radialGradient>
                        </defs>
                        <rect x="-2%" y="35%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(255 50 50)">
                            <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite" />
                            <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite" />
                            <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite" />
                        </rect>
                    </svg>
                </div>
                <div className="bg-color" style={{ background: 'rgba(235, 19, 109)' }}></div>
            </div>
            <div className="content-wrap deposit">
                <div className="content-box">
                    <h1>Get Ready!</h1>
                    <h3>Your Money is on its way.<br />You will get it within</h3>
                    <div className="arrival-time">{arrivalTime}</div>
                    <h2>min</h2>
                </div>
                <div className="ani-box">
                    <div className="before-amount" style={{ opacity: 1 }}>
                        <div className="icon-account" style={{
                            borderColor: 'rgba(239, 69, 140)',
                            background: 'linear-gradient(180deg, rgba(191, 15, 88) 0%, rgba(239, 69, 140) 100%)',
                            boxShadow: '0 0.5vw 0.5vw 0 rgb(45 45 45 / 10%), 0 1vw 1vw 0 rgb(49 49 49 / 10%)'
                        }}>
                            <span className="item-icon" style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/transfer-ani/account.svg")' }}></span>
                        </div>
                        <div className="currency">৳</div>
                        <div className="amount">{amount || "0"}</div>
                        <div className="player-account" style={{
                            borderColor: 'rgba(239, 69, 140)',
                            background: 'linear-gradient(180deg, rgba(191, 15, 88) 0%, rgba(239, 69, 140) 100%)',
                            boxShadow: '0 0.8vw 2.6vw 0 rgba(191, 15, 88)'
                        }}>{username}</div>
                    </div>
                    <div className="earth">
                        <div className="circle" style={{
                            borderColor: 'rgba(239, 168, 69, 0.1)',
                            background: 'rgba(239, 168, 69, 0.2)',
                            boxShadow: 'inset 0 0.2vw 10vw rgba(235,145,19)'
                        }}></div>
                        <video autoPlay playsInline muted loop className="ng-star-inserted" poster="https://img.j189eb.com/jb/h5/assets/images/transfer-ani/earth0080.png?v=1730260440232">
                            <source src="https://img.j189eb.com/jb/h5/assets/images/transfer-ani/earth-alpha.webm" type="video/webm" />
                            <source src="https://img.j189eb.com/jb/h5/assets/images/transfer-ani/earth-alpha.mov" type="video/quicktime" />
                        </video>
                        <div className="green-circle" style={{ opacity: 1 }}></div>
                        <div className="payment" style={{ opacity: 1 }}>
                            <img className="payment-logo"   src={paymentSystem?.icon ? `https://six6.site/storage/${paymentSystem.icon}` : "https://six6.site/default-icon.png"}  />
                        </div>
                    </div>
                    <div className="after-amount" style={{ opacity: 1 }}>
                        <div className="platform-logo">
                            <span className="item-icon" style={{ backgroundImage: 'url("./icons/wbj-white.png")' }}></span>
                        </div>
                        <div className="currency">৳</div>
                        <div className="amount">{amount || "0"}</div>
                        <div className="platform-name">WorldBaji</div>
                    </div>
                </div>
                <div className="button-box" style={{ opacity: 1 }}>
                    <Link to="/account">Close</Link>
                </div>
            </div>
        </div>
    );
}

export default PaymentTimer;
