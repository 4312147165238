import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEnvelope, faPaperPlane, faXmark, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import NoData from "../common/mobile/NoData";

function ChatHome  () {
    const [userData, setUserData] = useState({
        username: '',
        
    });
    const [promotions, setPromotions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [logo, setLogo] = useState([]);
    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
                if (!response.ok) {
                    throw new Error("Failed to fetch promotions");
                }
                const data = await response.json();
                setLogo(data[0].logo);
            } catch (error) {
                console.error("Error fetching promotions:", error);
            } 
        };
    
        fetchPromotions();
    }, []);
    const fetchUserData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        if (token && remoteId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const formattedDate = data.created_at ? new Date(data.created_at).toLocaleDateString('en-GB') : 'N/A';
                    
                    setUserData({
                        username: data.username || 'Unknown User',
                        
                    });
                } else {
                    console.error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await fetch("https://six6.site/api/promotion");
                if (!response.ok) {
                    throw new Error("Failed to fetch promotions");
                }
                const data = await response.json();
                // Filter promotions based on status
                const filteredPromotions = data.filter(promotion => promotion.status === 'display');
                setPromotions(filteredPromotions);
            } catch (error) {
                console.error("Error fetching promotions:", error);
            } finally {
                setLoading(false);
            }
        };
  
        fetchPromotions();
    }, []);
    if (loading) {
        return <div className="loading">Loading...</div>;
    }  


    return (
    <div className='live-chat'>
        <div className='chat-header'>
            <div className='chat-header-title'>
                <img src={`${process.env.REACT_APP_API_URL}/storage/${logo}`} alt='icon-sponsor' />
                </div>
            <div className='chat-header-buttons'>
            <Link to={'/'}>
            <FontAwesomeIcon className="text-white icon" icon={faXmark}/>
            </Link>
            </div>
        </div>
        <div className='chat-body'>
            <div className='chat-body-header'>
            <div className='support-team'>
                <img src='./avatars/150-2.jpg' alt='icon-sponsor' />
                <img src='./avatars/150-7.jpg' alt='icon-sponsor' />
                <img src='./avatars/150-13.jpg' alt='icon-sponsor' />
                <img src='./avatars/150-16.jpg' alt='icon-sponsor' />
                <img src='./avatars/150-17.jpg' alt='icon-sponsor' />
                <img src='./avatars/150-19.jpg' alt='icon-sponsor' />
            </div>
            <div className='chat-body-header-title'>
                <h1 className='chat-username'>Hi {userData.username}</h1>
                <h1>How can we Help?</h1>
                
            </div>
            
            </div>
            <div className='chat-card'>
                <div className='chat-card-body'>
                    <div className='chat-card-body-title'>
                        <span>Recect Message</span>
                    </div>
                    <div className='chat-history'>
                    <div className='chat-history-body'>
                    <img src='./avatars/blank.png' alt='icon-sponsor' />
                    <div>
                    <span>Rate your conversation</span>
                    <p>Aisha - 1d ago</p>
                    </div>
                    </div>
                    <div>
                    <FontAwesomeIcon className='text-dark icon' icon={faChevronRight} />
                    </div>
                    </div>
                    
                    
                </div>
            </div>
            <div className='chat-card'>
                <div className='chat-card-body'>
                    <Link to={'/chat-box'}>
                    <div className='chat-card-body-title'>
                        <span>Send us a Message</span>
                        <FontAwesomeIcon className='text-dark' icon={faPaperPlane} />
                    </div>
                    </Link>
                  
                    
                </div>
            </div>
            {promotions.length > 0 ? (
                    promotions.map((promotion) => (
            <div className='chat-card promotion-cd'>
                 <img className="w-100" src={`${process.env.REACT_APP_API_URL}/storage/${promotion.thumbnail}`} alt={promotion.name} loading="lazy" />
                <span>{promotion.name}</span>
                <p>{promotion.desc ? promotion.desc.split(' ').slice(0, 100).join(' ') : 'No description available'}</p>
            </div>
             ))
             ) : (
                <NoData/>
            )}
           
        </div>
        <div className='chat-footer'>
            
            <ul className='chat-nav'>
             <li>
                 <Link to={'/chat'}>
                     <FontAwesomeIcon icon={faEnvelopeOpen}/>
                     <p>Home</p>
                 </Link>
             </li>
             <li>
                 <Link to={'/chat-list'}>
                     <FontAwesomeIcon icon={faEnvelope}/>
                     <p>Message</p>
                 </Link>
             </li>
            </ul>
            
         
     </div>
       
        
        
       
    </div>
    );
}
export default ChatHome;