import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function Turnover() {
  const [activeTab, setActiveTab] = useState('active');
  const [turnovers, setTurnovers] = useState([]);
  const [transactionAmount, setTransactionAmount] = useState(0);
  const isLoggedIn = !!localStorage.getItem('token'); // Check if the user is logged in

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fetchTurnovers = async () => {
    try {
      const token = localStorage.getItem('token');
      const remoteId = localStorage.getItem('remote_id');
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/turnovers?remote_id=${remoteId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error(`Server Error: ${response.status} ${response.statusText}`);
        return;
      }
  
      const data = await response.json();
      setTurnovers(data.turnover || []);
      setTransactionAmount(data.transactionamount || 0);
    } catch (error) {
      console.error('Fetch Turnovers Error:', error);
    }
  };
  

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  return (
    <div className="main-content-wrap">
      <div className="fund-header">
        <div className="fund-action-bar">
          <Link to={'/account'}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <h6>Turnover</h6>
          <div>
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className="fund-nav">
          <ul className="p-3 mx-3">
            <li
              className={`nav-item ${activeTab === 'active' ? 'active' : ''}`}
              onClick={() => handleTabClick('active')}
            >
              Active
            </li>
            <li
              className={`nav-item ${activeTab === 'complete' ? 'active' : ''}`}
              onClick={() => handleTabClick('complete')}
            >
              Complete
            </li>
          </ul>
        </div>
      </div>

      <div className={`fund-body turnover-body ${activeTab === 'active' ? 'open' : ''}`}>
      {turnovers.filter(turnover => turnover.status === 'active').map(turnover => {
  const progress =
    turnover.required_turnover > 0
      ? Math.min((transactionAmount / turnover.required_turnover) * 100, 100) // Ensure progress is capped at 100%
      : 0; // Default progress to 0 if no turnover required

  return (
    <li className="ticket completed ng-star-inserted" key={turnover.id}>
      <div className="ticket-inner">
        <div className="ticket-inner-left">
          <div className="title">{turnover.transaction?.promotion?.name || 'Deposit Without any Promotion'}</div>
          <div className="detail">
            <div className="detail-btn">
              <a href="#">Detail</a>
            </div>
          </div>
          <div className="discount">
            <div className="amount">
              <i id="locale-util-dicrective-142">৳ {turnover.required_turnover}</i>
            </div>
          </div>
          <div className="progress-bar">
            <div className="bar">
              <div className="bar-inner" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="number">
              <span>৳{transactionAmount}</span>
              <span>{`${progress.toFixed(2)}%`}</span>
            </div>
          </div>
        </div>
        <div className="ticket-inner-right">
          <div className="text ng-star-inserted" style={{ color: 'yellow' }}>
            <span className="item-icon">
              <FontAwesomeIcon style={{ fontSize: '26px' }} className="text-warning" icon={faSpinner} />
            </span>
            Pending
          </div>
        </div>
      </div>
      <div className="ticket-deco open-pop">
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </li>
  );
})}
      </div>

      <div className={`fund-body turnover-body ${activeTab === 'complete' ? 'open' : ''}`}>
        {turnovers
          .filter((turnover) => turnover.status === 'complete')
          .map((turnover) => {
            return (
              <div className="bonus-wallet-wrap wallet-color-gray" key={turnover.id}>
                <div className="bonus-wallet">
                  <div className="title">{turnover.transaction?.promotion?.name || 'Deposit'}</div>
                  <div className="wallet-label wallet-label-complete">
                    <img src="https://www.jeetbuzz.com/images/web/icon-set/promotion/icon-promotion-state.png" alt="Promotion State" />
                    <span>Completed</span>
                  </div>
                  <div className="wallet-inner">
                    <div className="wallet-inner-left">
                      <div className="card-detail">
                        <div className="card-date">
                          Event ends in: {formatDate(turnover.updated_at)}
                        </div>
                        <div className="progress-group">
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: '100%' }}></div>
                          </div>
                          <span className="vip-now">৳ {turnover.required_turnover}</span>
                          <span className="vip-next">100%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Turnover;
